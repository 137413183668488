/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* End of reset.css */
@keyframes photoAnimationInSmall {
  0% {
    transform: translateY(70px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes photoAnimationInLarge {
  0% {
    transform: translateX(128px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    transform: translateX(128px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes nameAnimationInSmall {
  0%, 70% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes nameAnimationInLarge {
  0%, 70% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes iconAnimationIn {
  0%, 50% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

html, body {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  overflow: hidden;
}

h1, h2 {
  animation-fill-mode: both;
  animation: nameAnimationInSmall 1s;
}

h1 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
}

h2 {
  font-size: 18px;
  color: #777777;
  animation-fill-mode: backwards;
  animation-delay: 0.2s;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  margin-bottom: 10px;
  text-align: center;
}

header img {
  animation: photoAnimationInSmall 1s;
  margin: 10px 20px;
}

header section {
  margin-top: 5px;
}

header section h1, header section h2 {
  line-height: 36px;
}

.links {
  margin-top: 20px;
}

.links a {
  padding: 15px;
}

.links a:hover .links__icon {
  transform: scale(1.1);
}

.links .links__icon {
  animation: iconAnimationIn 1.6s;
  animation-fill-mode: both;
  transition: transform 200ms;
  width: 24px;
  height: 24px;
}

.links a:nth-child(2) .links__icon {
  animation-delay: 0.1s;
}

.links a:nth-child(3) .links__icon {
  animation-delay: 0.2s;
}

.decoration > * {
  position: absolute;
}

.decoration .decoration--orange {
  background: #f5a623;
  width: 420px;
  height: 128px;
}

.decoration .decoration-left--orange {
  display: none;
}

.decoration .decoration-right--orange {
  bottom: -75px;
  right: -75px;
  transform: rotate(-24deg);
}

.decoration .decoration-right-blue {
  bottom: -80px;
  right: 0px;
  width: 0;
  height: 0;
  border-left: 205px solid transparent;
  border-right: 160px solid transparent;
  border-bottom: 145px solid #4a5974;
  transform: rotate(149deg);
}

@media (min-width: 700px) {
  h1, h2 {
    animation-fill-mode: backwards;
    animation: nameAnimationInLarge 1s;
  }

  h2 {
    animation-fill-mode: backwards;
    animation-delay: 0.1s;
  }

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  header img {
    animation: photoAnimationInLarge 1s;
  }

  .links {
    margin-top: 10px;
  }

  .links a {
    padding-left: 0;
  }

  .links .links__icon {
    width: 18px;
    height: 18px;
  }

  .decoration .decoration-left--orange {
    display: block;
    left: 75px;
    transform: rotate(24deg);
  }
}
