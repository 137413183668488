html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@keyframes photoAnimationInSmall {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  40% {
    opacity: 1;
  }

  60% {
    transform: translateY(70px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes photoAnimationInLarge {
  0% {
    opacity: 0;
    transform: translateX(128px);
  }

  40% {
    opacity: 1;
  }

  60% {
    transform: translateX(128px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes nameAnimationInSmall {
  0%, 70% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes nameAnimationInLarge {
  0%, 70% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes iconAnimationIn {
  0%, 50% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

html, body {
  width: 100%;
  height: 100%;
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 300;
  position: relative;
  overflow: hidden;
}

h1, h2 {
  animation: 1s nameAnimationInSmall;
}

h1 {
  color: #222;
  font-size: 36px;
  font-weight: 500;
}

h2 {
  color: #777;
  font-size: 18px;
  animation-delay: .2s;
  animation-fill-mode: backwards;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

header {
  text-align: center;
  margin-bottom: 10px;
}

header img {
  margin: 10px 20px;
  animation: 1s photoAnimationInSmall;
}

header section {
  margin-top: 5px;
}

header section h1, header section h2 {
  line-height: 36px;
}

.links {
  margin-top: 20px;
}

.links a {
  padding: 15px;
}

.links a:hover .links__icon {
  transform: scale(1.1);
}

.links .links__icon {
  width: 24px;
  height: 24px;
  transition: transform .2s;
  animation: 1.6s both iconAnimationIn;
}

.links a:nth-child(2) .links__icon {
  animation-delay: .1s;
}

.links a:nth-child(3) .links__icon {
  animation-delay: .2s;
}

.decoration > * {
  position: absolute;
}

.decoration .decoration--orange {
  width: 420px;
  height: 128px;
  background: #f5a623;
}

.decoration .decoration-left--orange {
  display: none;
}

.decoration .decoration-right--orange {
  bottom: -75px;
  right: -75px;
  transform: rotate(-24deg);
}

.decoration .decoration-right-blue {
  width: 0;
  height: 0;
  border-bottom: 145px solid #4a5974;
  border-left: 205px solid #0000;
  border-right: 160px solid #0000;
  bottom: -80px;
  right: 0;
  transform: rotate(149deg);
}

@media (min-width: 700px) {
  h1, h2 {
    animation: 1s nameAnimationInLarge;
  }

  h2 {
    animation-delay: .1s;
    animation-fill-mode: backwards;
  }

  header {
    text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  header img {
    animation: 1s photoAnimationInLarge;
  }

  .links {
    margin-top: 10px;
  }

  .links a {
    padding-left: 0;
  }

  .links .links__icon {
    width: 18px;
    height: 18px;
  }

  .decoration .decoration-left--orange {
    display: block;
    left: 75px;
    transform: rotate(24deg);
  }
}

/*# sourceMappingURL=index.26df48bd.css.map */
